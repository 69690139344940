import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import _ from 'lodash';

import Footer from '../Footer';
import BuildRelationshipsSection from '../sections/BuildRelationshipsSection';
import Prose from '../Prose';

const PostTemplateDetails = ({ data }) => {
  const { wpPost } = data;
  const { name } = { name: data.wpPost.author.node.name };
  const { title, date, content } = wpPost;
  const tags = wpPost.tags.nodes.map((edge) => edge.name);

  return (
    <>
      <div className="mx-auto w-full px-4 pt-12 sm:px-6 sm:pt-16 lg:flex lg:justify-between lg:px-8 lg:pt-20 mb-16">
        <main className="prose mx-auto lg:prose-xl hover:prose-a:text-sp-green">
          <article>
            <section>
              <h1>{title}</h1>
              <h3 style={{ fontWeight: 'normal' }}>{name}</h3>
              {date ? moment(date).format('MMMM D, YYYY') : null}
            </section>

            <section>
              <Prose className="relative pt-10 pb-[4rem]" content={content} />
            </section>

            {tags.length ? (
              <div className="flex mb-3">
                {tags.map((tag) => (
                  <span className="px-4 py-2 rounded-full bg-sp-secondary-pastel-blue font-bold text-sm">{tag}</span>
                ))}
              </div>
            ) : null}

            <div className="container">
              <Link className="button" to="/blog">
                Read more posts
              </Link>
            </div>
          </article>
        </main>
      </div>

      <BuildRelationshipsSection />

      <Footer />
    </>
  );
};

export default PostTemplateDetails;
